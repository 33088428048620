angular.module('app')
    .factory('_apiService', function(CONFIG, $http, $q, _appStorage, $httpParamSerializer) {
        return {

            /**
             * Login page
             */

            login: function(username, password) {
                var req = {
                    method: 'POST',
                    url: CONFIG.serverAPI + '/oauth/token',
                    data: $httpParamSerializer({
                        grant_type: 'password',
                        scope: 'read',
                        username: username,
                        password: password
                    }),
                    headers: {
                        'Authorization': 'Basic Y3JvcG1vbml0b3ItbW9iaWxlOg==',
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    }
                };

                return $http(req);
            },
            signup: function(email, firstName, lastName, password) {
                var req = {
                    method: 'POST',
                    url: CONFIG.serverAPI + '/api/signup',
                    data: {
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                        password: password
                    }
                };

                return $http(req);
            },
            isEmailUnique: function(email) {
                var req = {
                    method: 'POST',
                    url: CONFIG.serverAPI + '/api/signup/email',
                    data: {
                        email: email
                    }
                };

                return $http(req);
            },
            restorePasswordAsk: function(email) {
                return $http.post(CONFIG.serverAPI + '/api/signup/password', {email: email});
            },
            restorePasswordCheck: function(code) {
                return $http.get(CONFIG.serverAPI + '/api/signup/password/?code=' + code);
            },
            restorePasswordSet: function(code, password) {
                return $http.put(CONFIG.serverAPI + '/api/signup/password', {code: code, password: password});
            },
            confirmEmail: function(code) {
                return $http.post(CONFIG.serverAPI + '/api/signup/confirm', {code: code});
            },
            approveInvitation: function(code) {
                return $http.post(CONFIG.serverAPI + '/api/signup/invitation', {code: code});
            },

            /**
             * header
             */


            navigation: function(accountId) {
                return $http.get(CONFIG.serverAPI + '/api/fields', {params: {accountId: accountId, fields: 'id,name,timezoneId,farm', embed: 'farms'}});
            },

            /**
             * field-panel
             */
            farmCrops: function(accountId, farmId) {
                return $http.get(CONFIG.serverAPI + '/api/sowings', {params: {farmId: farmId, showTasks: true, accountId: accountId}});
            },
            taskReportUrl: function (cmTaskId) {
                return CONFIG.serverAPI + '/api/cmtasks/' + cmTaskId + "/report";
            },
            taskXlsxReportUrl: function (cmTaskId) {
                return CONFIG.serverAPI + '/api/cmtasks/' + cmTaskId + "/report2xlsx";
            },
            taskReport2: function (cmTaskId, accountId) {
                return $http.get(CONFIG.serverAPI + '/api/cmtasks/' + cmTaskId + "/report2",  {params: {accountId: accountId}});
            },
            getVarieties: function(accountId) {
                return $http.get(CONFIG.serverAPI + '/api/varieties', {params: {accountId: accountId, regionFilter: true}});
            },
            updateCrop: function(accountId, crop) {
                var cropID = crop.id;
                delete crop.id;
                delete crop.variety.name;
                delete crop.variety.crop;

                return $http.put(CONFIG.serverAPI + '/api/sowings/' + cropID, crop, {params: {accountId: accountId, showTasks: true}});
            },
            createCrop: function(accountId, crop) {
                return $http.post(CONFIG.serverAPI + '/api/sowings', crop, {params: {accountId: accountId, showTasks: true}});
            },
            deleteCrop: function(accountId, crop) {
                return $http.delete(CONFIG.serverAPI + '/api/sowings/' + crop.id, {params: {accountId: accountId}});
            },
            thresholdUnit: function (accountId, cmTaskId, insectId) {
                return $http.get(CONFIG.serverAPI + '/api/cmtasks/' + cmTaskId + "/insects/" + insectId + "/thresholdUnit", {params: {accountId: accountId, cmTaskId: cmTaskId, insectId: insectId}});
            },
            sowingThresholds: function(accountId, cropId, insectId, thresholdUnit) {
                return $http.get(CONFIG.serverAPI + '/api/sowings/' + cropId + "/thresholds", {params: {accountId: accountId, insectId: insectId, thresholdUnit: thresholdUnit}});
            },
            sowingInsects: function(accountId, cropId, insectId, withNotes) {
                return $http.get(CONFIG.serverAPI + '/api/sowings/' + cropId + "/insects", {params: {accountId: accountId, insectId: insectId, withNotes: withNotes == true}});
            },
            sowingDiseases: function (accountId, cropId) {
                return $http.get(CONFIG.serverAPI + '/api/sowings/' + cropId + "/diseases", {params: {accountId: accountId}});
            },
            sowingDiseasesThresholds: function (accountId, cropId) {
                return $http.get(CONFIG.serverAPI + '/api/sowings/' + cropId + "/diseaseThresholds", {params: {accountId: accountId}});
            },
            getDangerMap: function(accountId, crop, date, excludeSprayTaskId, insectIds, noteInsectIds) {
                var p = {accountId: accountId, actualDate: date, insectIds: insectIds};
                if (excludeSprayTaskId) {
                    p.excludeSprayTaskId = excludeSprayTaskId;
                }
                if (_.size(noteInsectIds) > 0) {
                    p.noteInsectIds = noteInsectIds;
                }
                return $http.get(CONFIG.serverAPI + '/api/sowings/' + crop.id + "/dangermaps", {params: p});
            },


            /**
             * Weather Page
             */
            weatherSnapshotsAsync: function(accountId, fieldId, granularity, from, max) {
                var granularities = {
                    "h": "hour",
                    "d": "day",
                    "w": "week",
                    "m": "month",
                    "s": "stage"
                };

                granularity = granularities[granularity] || 'month';

                var req = {
                    method: 'GET',
                    url: CONFIG.serverAPI + '/api/fields/' + fieldId + '/weatherParams',
                    params: {
                        granularity: granularity,
                        from: from.format("YYYY-MM-DDTHH"),
                        max: max,
                        accountId: accountId
                    },
                    headers: {
                        'Authorization': 'Bearer ' + _appStorage.get('token')
                    }
                };

                return $http(req);
            },

            /**
             * Map Page
             * api that is meant to be used on any page with google map (map page, task page)
             */
            fields: function(accountId) {
                return $http.get(CONFIG.serverAPI + '/api/fields', {params: {accountId: accountId}});
            },
            updateFarms: function (accountId, farms) {
                return $http.post(CONFIG.serverAPI + '/api/farms', {farms: farms}, {params: {accountId: accountId}});
            },
            updateFields: function (accountId, fields) {
                return $http.post(CONFIG.serverAPI + '/api/fields/bulk', {fields: fields}, {params: {accountId: accountId}});
            },
            ndvi: function(accountId, fieldId) {
                return $http.get(CONFIG.serverAPI + '/api/fields/' + fieldId + '/ndvi', {params: {accountId: accountId}});
            },

            /**
             * Task page
             */
            saveAssignment: function(accountId, assignment) {
                return $http.post(CONFIG.serverAPI + '/api/cmtasks', assignment, {params: {accountId: accountId}});
            },
            getActivitiesDistributionOnDate: function(accountId, sowingId, date) {
                return $http.get(CONFIG.serverAPI + '/api/sowings/' + sowingId + '/activities', {params: {accountId: accountId, activityDate: date}})
                    .then(function(response) {
                        return response.data;
                    }
                );
            },
            cropTracks: function(accountId, sowingId) {
                return $http.get(CONFIG.serverAPI + '/api/sowings/'+sowingId+'/tracks/', {params: {accountId: accountId}, headers: {'Authorization': 'Bearer ' + _appStorage.get('token')}});
            },
            track: function(accountId, trackId, dividers, generateAveragedGrid = false) {
                return $http.get(CONFIG.serverAPI + '/api/spraytasks/tracks/'+trackId, {params: {accountId: accountId, generateGrid: true, dividers: dividers, generateAveragedGrid: generateAveragedGrid}, headers: {'Authorization': 'Bearer ' + _appStorage.get('token')}});
            },


            /**
             * Spray
             */
            saveSpray: function(accountId, spray) {
                return $http.post(CONFIG.serverAPI + '/api/spraytasks', spray, {params: {accountId: accountId}});
            },
            updateSpray: function(accountId, spray) {
                return $http.put(CONFIG.serverAPI + '/api/spraytasks/' + spray.id, spray, {params: {accountId: accountId}});
            },
            getSpray: function(accountId, sprayId) {
                return $http.get(CONFIG.serverAPI + '/api/spraytasks/' + sprayId, {params: {accountId: accountId}});
            },
            chemicals: function(accountId) {
                return $http.get(CONFIG.serverAPI + '/api/chemicals', {params: {accountId: accountId}});
            },
            mixes: function(accountId) {
                return $http.get(CONFIG.serverAPI + '/api/mixes', {params: {accountId: accountId}});
            },
            updateMix: function(accountId, mix) {
                return $http.put(CONFIG.serverAPI + '/api/mixes/' + mix.id, mix, {params: {accountId: accountId}});
            },
            saveMix: function(accountId, mix) {
                return $http.post(CONFIG.serverAPI + '/api/mixes', mix, {params: {accountId: accountId}});
            },
            /**
             * Settings page
             */

            accountUsers: function (accountId) {
                return $http.get(CONFIG.serverAPI + '/api/accounts/me/users', {params: {accountId: accountId}});
            },
            updateAccount: function (accountId, name, settings) {
                return $http.put(CONFIG.serverAPI + '/api/accounts/me', {accountId: accountId, name: name, settings: settings}, {params: {accountId: accountId}});
            },
            saveUser: function (accountId, user) {
                user.account = {id: accountId};
                return $http.post(CONFIG.serverAPI + '/api/users', user, {params: {accountId: accountId}});
            },
            updateUser: function (accountId, user) {
                return $http.put(CONFIG.serverAPI + '/api/users', user, {params: {accountId: accountId}});
            },
            addAccount: function (name) {
                return $http.post(CONFIG.serverAPI + '/api/accounts', {name: name});
            },
            currentUser: function () {
                return $http.get(CONFIG.serverAPI + '/api/users/me', {headers: {'Authorization': 'Bearer ' + _appStorage.get('token')}});
            },
            updatePassword: function (oldPassword, newPassword, newPasswordConfirm) {
                return $http.put(CONFIG.serverAPI + '/api/users/me/password', {oldPassword: oldPassword, password: newPassword, passwordConfirm: newPasswordConfirm});
            },
            deleteUser: function (accountId, userId) {
                return $http.delete(CONFIG.serverAPI + '/api/accounts/me/users/' + userId, {params: {accountId: accountId}});
            },
            resendInvitation: function (accountId, userId) {
                return $http.post(CONFIG.serverAPI + '/api/accounts/me/invitations', {id: userId}, {params: {accountId: accountId}});
            },
            deleteInvitation: function (accountId, userId) {
                return $http.delete(CONFIG.serverAPI + '/api/accounts/me/invitations', {params: {accountId: accountId, userId: userId}});
            },

            /**
             * Other
             * api that is meant to be used on any page
             */

            allCrops: function(accountId, showStages, showDiseaseMarks) {
                var p = { accountId: accountId};
                var embed = [];
                if (showStages) {
                    embed.push("stages");
                }
                if (showDiseaseMarks) {
                    embed.push("diseaseMarks")
                }
                if (embed.length > 0) {
                    p.embed = embed.join(',');
                }

                return $http.get(CONFIG.serverAPI + '/api/sowings', {params: p});
            },
            fieldCrops: function(accountId, fieldId, showTasks = true, showStages, seedOrderAsc) {
                var p = {fieldId: fieldId, seedOrderAsc: angular.isDefined(seedOrderAsc) ? true : false, accountId: accountId};
                if (showTasks) {
                    if (showStages) {
                        p.embed = "tasks,stages";
                    } else {
                        p.embed = "tasks";
                    }
                } else {
                    if (showStages) {
                        p.embed = "stages";
                    }
                }
                return $http.get(CONFIG.serverAPI + '/api/sowings', {params: p});
            },
            deleteCropMonitor: function(accountId, taskId) {
                return $http.delete(CONFIG.serverAPI  + '/api/cmtasks/' + taskId, {params: {accountId: accountId}});
            },
            deleteSpray: function(accountId, taskId) {
                return $http.delete(CONFIG.serverAPI  + '/api/spraytasks/' + taskId, {params: {accountId: accountId}});
            },
            fieldSowingsOnDate: function(accountId, fieldId, date) {
                return $http.get(CONFIG.serverAPI + '/api/sowings', {params: {fieldId: fieldId, accountId: accountId, sowingDate: date}});
            },
            getTaskDetails: function(accountId, taskId) {
                return $http.get(CONFIG.serverAPI + '/api/cmtasks/' + taskId, {params: {accountId: accountId}});
            },
            getTaskTrack: function(accountId, taskId) {
                return $http.get(CONFIG.serverAPI + '/api/cmtasks/' + taskId + '/track', {params: {accountId: accountId}});
            },
            getTaskCellInsectsChart: function(accountId, taskId, cellIndex, insectId, withNotes) {
                return $http.get(CONFIG.serverAPI + '/api/cmtasks/' + taskId + '/cells/' + cellIndex[0] + "," + cellIndex[1]+ '/insectsCounts', {params: {accountId: accountId, taskId: taskId, insectId: insectId, withNotes: withNotes == true}});
            },
            getTaskInsects: function (accountId, taskId) {
                return $http.get(CONFIG.serverAPI + '/api/cmtasks/' + taskId + '/insects', {params: {accountId: accountId, taskId: taskId}});
            },
            getCellInsects: function (accountId, taskId, cellIndex) {
                return $http.get(CONFIG.serverAPI + '/api/cmtasks/' + taskId + '/cells/' + cellIndex[0] + "," + cellIndex[1] +'/insects', {params: {accountId: accountId, taskId: taskId}});
            },
            getInsects: function(accountId){
                return $http.get(CONFIG.serverAPI + '/api/insects', {params: {accountId: accountId, regionFilter: true}});
            },
            getDiseases: function(accountId) {
                return $http.get(CONFIG.serverAPI + '/api/diseases', {params: {accountId: accountId, regionFilter: true}});
            },
            getWeeds: function(accountId) {
                return $http.get(CONFIG.serverAPI + '/api/weeds', {params: {accountId: accountId, regionFilter: true}});
            },
            // getCrops: function(accountId) {
            //     return $http.get(CONFIG.serverAPI + '/api/crops', {params: {accountId: accountId}});
            // },
            updatePointResult: function(accountId, pointId, id, result){
                return $http.put(CONFIG.serverAPI + '/api/cmpoints/' + pointId + '/results/' + id, result, {params: {accountId: accountId}});
            },
            updatePhotoNoteResult: function(accountId, id, result){
                return $http.put(CONFIG.serverAPI + '/api/photonotes/results/' + id, result, {params: {accountId: accountId}});
            },
            saveInsectThresholds: function(accountId, insectId, thresholds) {
                return $http.post(CONFIG.serverAPI + '/api/insects/'+insectId+'/thresholds', thresholds, {params: {accountId: accountId}});
            },
            saveDiseaseThresholds: function(accountId, diseaseId, thresholds) {
                return $http.post(CONFIG.serverAPI + '/api/diseases/'+diseaseId+'/thresholds', thresholds, {params: {accountId: accountId}});
            },
            saveWeedThresholds: function(accountId, weedId, thresholds) {
                return $http.post(CONFIG.serverAPI + '/api/weeds/'+weedId+'/thresholds', thresholds, {params: {accountId: accountId}});
            },
            insectThresholds: function(accountId, cropId, insectId) {
                return $http.get(CONFIG.serverAPI + '/api/insects/'+insectId+'/thresholds', {params: {accountId: accountId, cropId: cropId}});
            },
            insectThresholdsFlags: function(accountId, cropId) {
                return $http.get(CONFIG.serverAPI + '/api/insects/thresholdsFlags/' + cropId, {params: {accountId: accountId}});
            },
            insectThresholdsReset: function(accountId, insectId, thresholdUnit, cropId) {
                return $http.post(CONFIG.serverAPI + '/api/insects/'+insectId+'/thresholds/reset', {insectId: insectId, thresholdUnit: thresholdUnit, cropId: cropId}, {params: {accountId: accountId}});
            },
            weedThresholdsFlags: function(accountId, cropId) {
                return $http.get(CONFIG.serverAPI + '/api/weeds/thresholdsFlags/' + cropId, {params: {accountId: accountId}});
            },
            weedThresholdsReset: function(accountId, weedId, cropId) {
                return $http.post(CONFIG.serverAPI + '/api/weeds/'+weedId+'/thresholds/reset', {cropId: cropId}, {params: {accountId: accountId}});
            },
            diseaseThresholdsReset: function(accountId, diseaseId, cropId) {
                return $http.post(CONFIG.serverAPI + '/api/diseases/'+diseaseId+'/thresholds/reset', {cropId: cropId}, {params: {accountId: accountId}});
            },
            diseaseThresholdsFlags: function(accountId, cropId) {
                return $http.get(CONFIG.serverAPI + '/api/diseases/thresholdsFlags/' + cropId, {params: {accountId: accountId}});
            },
            insectScales: function(accountId, insectId) {
                return $http.get(CONFIG.serverAPI + '/api/insects/'+insectId+'/evaluationscales', {params: {accountId: accountId}});
            },
            diseaseThresholds: function(accountId, cropId, diseaseId) {
                return $http.get(CONFIG.serverAPI + '/api/diseases/'+diseaseId+'/thresholds', {params: {accountId: accountId, cropId: cropId}});
            },
            diseaseScales: function(accountId, diseaseId) {
                return $http.get(CONFIG.serverAPI + '/api/diseases/'+diseaseId+'/evaluationscales', {params: {accountId: accountId}});
            },
            diseaseAllScales: function(accountId) {
                return $http.get(CONFIG.serverAPI + '/api/diseases/evaluationscales', {params: {accountId: accountId}});
            },
            weedThresholds: function(accountId, cropId, weedId) {
                return $http.get(CONFIG.serverAPI + '/api/weeds/'+weedId+'/thresholds', {params: {accountId: accountId, cropId: cropId}});
            },
            listPhotoNote: function(accountId, from, to, lonMin, latMin, lonMax, latMax) {
                return $http.get(CONFIG.serverAPI + '/api/photonotes', {params: {
                        accountId: accountId,
                        from: from.format(),
                        to: to.format(),
                        lonMin: lonMin,
                        latMin: latMin,
                        lonMax: lonMax,
                        latMax: latMax
                    }});
            },
            getPhotoNote: function(accountId, id) {
                return $http.get(CONFIG.serverAPI + '/api/photonotes/' + id, {params: {accountId: accountId}});
            },
            avatarUpload(accountId, file) {
                return $http({
                    method: 'POST',
                    url: CONFIG.serverAPI + '/api/avatar/upload',
                    headers: { 'Content-Type': undefined },
                    data: { uploadedFile: file },
                    params: { accountId: accountId },
                    transformRequest: function (data) {
                        var formData = new FormData();
                        angular.forEach(data, function (value, key) {
                            formData.append(key, value);
                        });

                        return formData;
                    }
                })
            },
            uploadFields(accountId, file){
                return $http({
                    method: 'POST',
                    url: CONFIG.serverAPI + '/api/fields/upload',
                    headers: { 'Content-Type': undefined },
                    data: { uploadedFile: file },
                    params: { accountId: accountId },
                    transformRequest: function (data) {
                        var formData = new FormData();
                        angular.forEach(data, function (value, key) {
                            formData.append(key, value);
                        });

                        return formData;
                    }
                })
            }
        };
    });